import React from "react"
import styles from "./styles.module.scss"
import ueFundPL from "../../../static/images/ue/ue-fund.png"
import ueFundEN from "../../../static/images/ue/ue-fund-en.png"
import ueFundLogoPL from "../../../static/images/ue/ue-fund1.png"
import ueFundLogoEN from "../../../static/images/ue/ue-fund1-en.png"
import rpLogoPL from "../../../static/images/ue/rp.png"
import rpLogoEN from "../../../static/images/ue/rp-en.png"
import { useIntl } from "gatsby-plugin-intl"
import css from "classnames"

const DonationInfo = () => {
  const intl = useIntl()
  return <div className={styles.DonationInfo}>
    <div className={"container"}>
      {intl.locale === "pl" && <>
        {/*<div className={css(styles.logos)}>*/}
        {/*  <img src={ueFundLogoPL} className={styles.logo} alt={"Fundusze Europejskie - Inteligenty Rozwój"} />*/}
        {/*  <img src={rpLogoPL} className={css(styles.logo, styles.shadowed)} alt={"Rzeczpospolita Polska"} />*/}
        {/*  <img src={ueFundPL} className={styles.logo}*/}
        {/*       alt={"Unia Europejska - Europejski Fundusz Rozwoju Regionalnego"} />*/}
        {/*</div>*/}
        <div className={styles.content}>
          <p>
            <strong>Cele projektu: </strong> Przedmiotem projektu jest opracowanie technologii produkcji i nanoszenia
            innowacyjnych zawierających aktywne składniki ceramiczne, powłok lakierniczych i komponentów, o
            zwiększonej trwałości i wysokich właściwościach użytkowych. Opracowane w ramach projektu
            rozwiązania odróżniać się będą od obecnie stosowanych rozwiązań licznymi wskaźnikami
            świadczącymi o ich innowacyjności. Parametry te zostaną osiągnięte poprzez zastosowanie unikalnej
            w skali światowej kompozycji napełniaczy i składników aktywnych, w skład których wejdą m.in.
            zeolity i geopolimery. Wytworzona kompozycja znajdzie odbiorców pośród zakładów
            produkcyjnych, które w swoim zapleczu technologicznym posiadają własne lakiernie proszkowe, a
            których wymagania produktowe wymagają podwyższonej odporności powłok lakierniczych, zaś
            usługa lakierowania do branży budowlanej, morskiej i Automotive.
          </p>
          <p>
            <strong>Planowane efekty:</strong> W rezultacie przeprowadzonych badań zostanie opracowany innowacyjny
            produkt
            oferowany w dwóch postaciach: w postaci wieloskładnikowej ciekłej kompozycji zapewniającej
            podwyższone parametry użytkowe, oraz w postaci naniesionej i utwardzonej powłoki lakierniczej na
            rzeczywistym obiekcie, zapewniającej dłuższy okres gwarancyjny w stosunku do produktów
            oferowanych obecnie na rynku.
          </p>
          <p><strong>Wartość projektu</strong>: 9 008 750,00 PLN</p>
          <p><strong>Wkład Funduszy Europejskich:</strong> 5 721 500,00 PLN</p>
        </div>
      </>}
      {intl.locale !== "pl" && <>
        {/*<div className={styles.logos}>*/}
        {/*  <img src={ueFundLogoEN} className={styles.logo} alt={"European Funds - Smart Growth"} />*/}
        {/*  <img src={rpLogoEN} className={styles.logo} alt={"Republic of Poland"} />*/}
        {/*  <img src={ueFundEN} className={styles.logo} alt={"Eurpean Union - Eurpean Regional Development Fund"} />*/}
        {/*</div>*/}
        <div className={styles.content}>
          <p>
            <strong>Aim of the project:</strong> The subject of the project is the development of technology for the production
            and application of innovative ceramic coatings and components containing active ceramic components, with
            increased durability and high performance properties. The solutions developed as part of the project will
            differ from the currently used solutions by numerous indicators proving their innovation. These parameters
            will be achieved by the use of a world-unique composition of fillers and active ingredients, which will
            include zeolites and geopolymers. Created composition will find customers
            among production plants that have their own powder coating shops in their technological facilities, and
            whose product requirements require increased resistance of varnish coatings, and the varnishing service for
            the construction, maritime and automotive industries.
          </p>
          <p>
            <strong>Planned effects:</strong> As a result of the research, an innovative product will be developed
            and offered in two forms: as a multi-component liquid providing composition
            increased operational parameters, and in the form of an applied and hardened varnish coat on
            real facility, ensuring a longer warranty period for the products that are
            currently offered on the market.
          </p>
          <p><strong>Value of the project:</strong> 9 008 750,00 PLN</p>
          <p><strong>Contribution of European Funds:</strong> 5 721 500,00 PLN</p>
        </div>
      </>}
    </div>
  </div>
}

export default DonationInfo
