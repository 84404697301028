import React from "react"
import { FormattedMessage, useIntl, Link } from "gatsby-plugin-intl"
import slide1 from "../../../static/images/slider/slide-1.jpg"
import slide2 from "../../../static/images/slider/slide-2.jpg"
import slide3 from "../../../static/images/slider/slide-3.jpg"
import slide4 from "../../../static/images/slider/slide-4.jpg"
import styles from "./styles.module.scss"
import css from "classnames"
import { useBoolean, useInterval } from "react-use"

const INTERVAL = 5000
const SLIDES = [
  // {
  //   url: "/spawalnictwo",
  //   image: slide1,
  //   text: "slider.slide1.title"
  // },
  {
    url: "/lakiernictwo",
    image: slide2,
    text: "slider.slide2.title"
  },
  {
    url: "/magazynowanie",
    image: slide3,
    text: "slider.slide3.title"
  },
  {
    url: "/lakiernictwo",
    image: slide4,
    text: "slider.slide4.title"
  }
]

const SLIDES_NUMBER = SLIDES.length

const Slider = () => {
  const intl = useIntl()
  const [delay, setDelay] = React.useState(INTERVAL)
  const [isRunning, toggleIsRunning] = useBoolean(true)

  useInterval(
    () => {
      handleNextSlide()
    },
    delay
  )

  const [activeSlide, setActiveSlide] = React.useState(0)

  const handleNextSlide = (clear = true) => {
    setDelay(INTERVAL + activeSlide)
    if (activeSlide < SLIDES_NUMBER - 1) {
      setActiveSlide(activeSlide + 1)
    } else {
      setActiveSlide(0)
    }
  }

  const handlePrevSlide = () => {
    setDelay(INTERVAL + activeSlide)
    if (activeSlide > 0) {
      setActiveSlide(activeSlide - 1)
    } else {
      setActiveSlide(SLIDES_NUMBER - 1)
    }
  }


  return <section className={styles.Slider}>
    <button className={styles.prevButton} onClick={handlePrevSlide}>
      <i className="fa fa-arrow-left" aria-hidden="true" />
    </button>
    <button className={styles.nextButton} onClick={handleNextSlide}>
      <i className="fa fa-arrow-right" aria-hidden="true" />
    </button>
    {SLIDES.map((slide, index) =>
      <div className={css(styles.slide, { [styles.active]: activeSlide === index })}
           style={{ backgroundImage: `url(${slide.image})` }}>
        <div className={styles.slideContent}>
          <div className="container">
            <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: slide.text }) }} />
            <Link to={slide.url} className={styles.moreButton}>
              <FormattedMessage id={"slider.moreButton"} />
            </Link>
          </div>
        </div>
      </div>)}
  </section>
}

export default Slider
