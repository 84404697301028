import React from "react"

const HomePL = () => <>
  <h2><span>O naszej</span> firmie</h2>
  <p>HIBRID Sp. z o.o. oferuje usługi w zakresie ochrony konstrukcji stalowych przed korozją i produkuje
    elementy konstrukcyjne dla przemysłu. Obecnie nasz zespół opracowuje przyjazne dla środowiska,
    nowej generacji farby antykorozyjne dla branż: motoryzacyjnej, kolejowej, stoczniowej, lotniczej i
    budowlanej.</p>
  <p>Nasze działania koncentrują się na czterech segmentach: spawanie, lakierowanie, logistyka i
    doradztwo techniczne. Jesteśmy w stanie dostosować się do wszelkich wymagań klienta. Cechą
    naszych działań jest nowatorstwo i elastyczność rozwiązań, wysoka precyzja i spełnianie najbardziej
    rygorystycznych standardów przemysłowych.</p>
</>

export default HomePL
