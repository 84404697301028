import React from "react"

const HomeEN = () => <>
  <h2><span>About </span> our company</h2>
  <p>HIBRID Pty Ltd is a company offering services in the field of corrosion protection of steel
    constructions and manufacturing specialized items for the industry. Currently, our team is
    developing a new generation and environmentally friendly anti-corrosive paints for the automotive,
    railway, marine, aviation, and metal industry.</p>
  <p>Our activities focus on four segments: welding, lacquering (painting), logistics and technical advice.
    We are able to respond to all customer requirements. Feature of our activities is the innovation and
    flexibility of solutions, high precision and also meet the most stringent industry standards.</p>
</>

export default HomeEN;