import React from "react"
import Header from "../../components/Header"
import Slider from "../../components/Slider"
import Footer from "../../components/Footer"
import css from "classnames"
import styles from "./styles.module.scss"
import DonationInfo from "../../components/DonationInfo"

const Home = ({ children }) => {
  return <div className={styles.HomeLayout}>
    <Header />
    <Slider />
    <section className={css(styles.content, "container")}>
      <div className="row justify-content-md-center">
        <div className="col-12">
          {children}
        </div>
      </div>
    </section>
    <DonationInfo />
    <Footer />
  </div>
}
export default Home
