import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import "../scss/main.scss"
import HomeLayout from "../layouts/Home"
import HomeContent from "../content/Home"

export default function IndexPage() {
  const intl = useIntl()
  return <HomeLayout>
    <HomeContent lang={intl.locale} />
  </HomeLayout>
}
