import React from "react"
import HomePL from "./home.pl"
import HomeEN from "./home.en"
import HomeDE from "./home.de"

const HomeContent = ({ lang }) => {
  if (lang === "de") {
    return <HomeDE />
  } else if (lang === "en") {
    return <HomeEN />
  }
  return <HomePL />
}

export default HomeContent