import React from "react"

const HomeDE = () => <>
  <h2><span>Über</span> uns</h2>
  <p>Wir sind ein Unternehmen, das ein breites Spektrum an Dienstleistungen und Produktion von notwendigen
    Spezialkomponenten anbieten kann. Bei uns wird die hohe Präzision großgeschrieben und stellt zugleich eine klare
    Grenze.</p> <p>Unsere Tätigkeit beruht auf vier globalen Bereichen. Dadurch können wir die höchsten
  Industriestandards erfüllen und die meist effizienten Lösungen für die anspruchsvollen Projekte liefern. Wir sind
  ein kompetenter Partner, der immer am Kundenbedarf orientiert ist.</p> <p>Unser Team arbeitet bereits an
  ökologischen Produkten und Verfahrensentwicklung neuer Generation für die Automobil-, Bahn-, Flug- und
  Metallindustrie sowie für den Schiffbau.</p> <p>Unser Angebot richtet sich an Kunden, die einen großen Wert auf
  moderne Lösungen sowie auf eine komplexe Bedienung und Zusammenarbeit legen.</p> <p>Ihr Erfolg ist auch unser
  Erfolg. Die Zufriedenheit unserer Kunden ist unser größtes Ziel, dadurch können wir als Unternehmen auch
  weiterentwickeln.</p>
</>

export default HomeDE